import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { toast, ToastContainer } from 'react-toastify'
import CSLoader from './components/common/Loading'
import NextTopLoader from 'nextjs-toploader';
import 'rsuite/dist/rsuite.min.css';


createRoot(document.getElementById('root')).render(
  <Provider store={store}>
   
    <App>
    <CSLoader/>
    </App>
    <ToastContainer />
  
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
