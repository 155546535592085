import React from 'react'
import {
    CSpinner,
  } from '@coreui/react'
  import { useDispatch, useSelector } from 'react-redux'
export default function CSLoader() {
    // const { loading } = useSelector((state) => state.loading);
        // loading ?
{/* <div className="d-flex align-items-center">
<div className="flex-grow-0 ms-3">
<div className="d-flex justify-content-start"><CSpinner color="info" size="xl" /> </div>
</div>
<div className="flex-grow-1 m-1">
  Loading ... 
</div>
</div> */}
  return (


<div className="spinner-border text-danger" role="status">
{/* <span className="">Loading...</span> */}
</div>
  )
}



