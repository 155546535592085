import React from 'react'
import { Navigate, Outlet, useLocation, useMatch } from 'react-router-dom'
import authService from '../services/auth.service'
import { useSelector, useDispatch } from 'react-redux'

export const AuthRoute = (props) => {
  const dispatch = useDispatch()
  // console.log(props)
  const location = useLocation()
  const { user: currentUser } = useSelector((state) => state.auth)
  let Noauth = true
  // console.log(currentUser)
  if (currentUser) {
    // console.log(currentUser.token.accessToken)
    // console.log(authService.checkTokenExpiry(currentUser.token.accessToken))
    Noauth = authService.checkTokenExpiry(currentUser.token.accessToken) ? true : false
  }
  // console.log('Current location:', location.pathname)
  var outlet = <Outlet />
  if (location.pathname == '/') {
    outlet = <Navigate to="/login" />
  }
  //   console.log(this.name)
  //   console.log('Current route name:')
  return Noauth ? outlet : <Navigate to="/dashboard" />
}
