import { combineReducers } from 'redux'
import auth from './auth'
import message from './message'
import theme from './theme'
import loading from './loading'
import outletReducer from './outletReducer';


export default combineReducers({
  auth,
  message,
  theme,
  loading,
  outletReducer,
})
