import { useSelector } from 'react-redux'

class ReduxHelper {
  getAccessToken = () => {
    const { AuthInstance } = useSelector((state) => state.auth)
    const jwtToken = AuthInstance.user?.token.accessToken
    console.log(jwtToken)
    return jwtToken
  }
}

export default new ReduxHelper()
