import routes from '../../../routes'

class RouteHelper {
  
  getRouteName = (pathname, routes) => {
    const pathParts = pathname.split('/').filter(part => part !== ''); // split the pathname and remove any empty parts
    const route = routes.find(route => {
      const routeParts = route.path.split('/').filter(part => part !== ''); // split the route path and remove any empty parts
      if (routeParts.length !== pathParts.length) { // check that the route and pathname have the same number of parts
        return false;
      }
      for (let i = 0; i < routeParts.length; i++) {
        if (routeParts[i][0] !== ':' && routeParts[i] !== pathParts[i]) { // if the part is not a parameter and does not match, the route does not match
          return false;
        }
      }
      return true;
    });
    return route ? route.name : false;
  }
  
  getRouteScope = (pathname, routes) => {
    const pathParts = pathname.split('/').filter(part => part !== ''); // split the pathname and remove any empty parts
    const route = routes.find(route => {
      const routeParts = route.path.split('/').filter(part => part !== ''); // split the route path and remove any empty parts
      if (routeParts.length !== pathParts.length) { // check that the route and pathname have the same number of parts
        return false;
      }
      for (let i = 0; i < routeParts.length; i++) {
        if (routeParts[i][0] !== ':' && routeParts[i] !== pathParts[i]) { // if the part is not a parameter and does not match, the route does not match
          return false;
        }
      }
      return true;
    });
    return route ? route.scope : "PUBLIC";
  }
  

  getRouteModuleID = (pathname, routes) => {
    const pathParts = pathname.split('/').filter(part => part !== ''); // split the pathname and remove any empty parts
    const route = routes.find(route => {
      const routeParts = route.path.split('/').filter(part => part !== ''); // split the route path and remove any empty parts
      if (routeParts.length !== pathParts.length) { // check that the route and pathname have the same number of parts
        return false;
      }
      for (let i = 0; i < routeParts.length; i++) {
        if (routeParts[i][0] !== ':' && routeParts[i] !== pathParts[i]) { // if the part is not a parameter and does not match, the route does not match
          return false;
        }
      }
      return true;
    });
    return route ? route.moduleID : null;
  }
  
  
}

export default new RouteHelper()
