class LocalStorageService {
  saveToLocalStorage = (state, data) => {
    try {
      localStorage.setItem(state, JSON.stringify(data))
    } catch (e) {
      console.error(e)
    }
  }
  loadFromLocalStorage = (state) => {
    try {
      const stateStr = localStorage.getItem(state)
      return stateStr ? JSON.parse(stateStr) : undefined
    } catch (e) {
      console.error(e)
      return undefined
    }
  }
}
export default new LocalStorageService()
