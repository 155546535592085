import { SET_OUTLET_ID, CLEAR_OUTLET_ID } from '../actions/outletActionTypes';

// Initial state
const initialState = {
  outlet_id: '',
  selectedOutlet:''
};

// Reducer
const outletReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OUTLET_ID:
      return {
        ...state,
        selectedOutlet: action.outlet_id
      };
    case CLEAR_OUTLET_ID:
      return {
        ...state,
        outlet_id: null
      };
    default:
      return state;
  }
};

export default outletReducer;
