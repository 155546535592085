import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers/index'
import thunk from 'redux-thunk'
import LocalStorageService from './modules/core/helper/LocalStorageService'
const middleware = [thunk]
const persistedStore = LocalStorageService.loadFromLocalStorage('state')
// const store = configureStore(rootReducer, persistedStore);
const store = createStore(
  rootReducer,
  persistedStore,
  composeWithDevTools(applyMiddleware(...middleware)),
)
store.subscribe(() => {
  LocalStorageService.saveToLocalStorage('state', store.getState())
})
export default store
