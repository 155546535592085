import React from 'react'
import axios from 'axios'
import { Navigate, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'
import ReduxHelper from '../helper/ReduxHelper'
const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const accessToken = token?.accessToken
    // console.log(accessToken)
    config.headers['Authorization'] = `Bearer ${accessToken}`
    return config
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // handle 401 errors here
      const navigate = useNavigate()
      console.log('Unauthorized access! Redirecting to login page...')
      navigate('/login')
      // perform any necessary actions, such as logging out the user or redirecting to a login page
    }
    return Promise.reject(error)
  },
)

// create the interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // handle 401 errors here
      const navigate = useNavigate()
      console.log('Unauthorized access! Redirecting to login page...')
      navigate('/login')
      // perform any necessary actions, such as logging out the user or redirecting to a login page
    }

    return Promise.reject(error)
  },
)
export default axiosInstance
