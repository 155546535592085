import React from 'react'
const MOD_DASHBOARD = React.lazy(() => import('./modules/dashboard/Dashboard'))
const MOD_CORE_PROFILE = React.lazy(() => import('./modules/profile/Profile'))

const APP_WEBHOOK_LOGS = React.lazy(() => import('./modules/WebhookLogs/WebHookLogs'))
const APP_INVOICE_LOGS = React.lazy(() => import('./modules/InvoiceLogs/InvoiceLogs'))
const APP_INVOICE_VIEW = React.lazy(() => import('./modules/InvoiceLogs/InvoiceDetailsPage'))
const APP_BATCH_INVOICE_LOG = React.lazy(() => import('./modules/BatchInvoiceLogs/BatchInvoiceLogs'))
const APP_BATCH_INVOICE_VIEW = React.lazy(() => import('./modules/BatchInvoiceLogs/BatchInvoiceDetailsPage'))


// Buttons

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: MOD_DASHBOARD,
    moduleID: 'MOD_DASHBOARD',
  },
  {
    path: '/profile',
    name: 'Profile',
    element: MOD_CORE_PROFILE,
    moduleID: 'MOD_CORE_PROFILE',
  },
  
  {
    path: '/webhook-logs',
    name: 'WebHook Logs',
    element: APP_WEBHOOK_LOGS,
    moduleID: 'APP_WEBHOOK_MASTER',
  },
  {
    path: '/webhook-logs/view',
    name: 'WebHook Logs',
    element: APP_WEBHOOK_LOGS,
    moduleID: 'APP_WEBHOOK_MASTER',
  },
  {
    path: '/invoice-logs',
    name: 'Invoice Logs',
    element: APP_INVOICE_LOGS,
    moduleID: 'APP_INVOICE_MASTER',
  },
  {
    path: '/invoice-logs/view/:id',
    name: 'Invoice Logs',
    element: APP_INVOICE_VIEW,
    moduleID: 'APP_INVOICE_MASTER',
  },{
    path: '/batch-invoice',
    name: 'Batch Invoices',
    element: APP_BATCH_INVOICE_LOG,
    moduleID: 'APP_INVOICE_MASTER',
  },
  {
    path: '/batch-invoice/view/:id',
    name: 'Batch Invoices Details',
    element: APP_BATCH_INVOICE_VIEW,
    moduleID: 'APP_INVOICE_MASTER',
  },
]
export default routes
