import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'
import authService from '../services/auth.service'
import { useSelector, useDispatch } from 'react-redux'
import RouteHelper from '../helper/RouteHelper'


import routes from 'src/routes'

export const PrivateRoute = (props) => {
  const [moduleDetails, setModuleDetails] = useState(null)
  const [AuthFlag, SetAuthFlag] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [isModuleActive, setModuleActiveFlag] = useState(true);
  var outlet = <Outlet />
  const location = useLocation()
  const navigate = useNavigate()
  const currentLocation = useLocation().pathname
  const RouteName = RouteHelper.getRouteName(currentLocation, routes)
  const RouteScope = RouteHelper.getRouteScope(currentLocation, routes)
  const RouteModuleID = RouteHelper.getRouteModuleID(currentLocation, routes)
  document.title = 'GoAndroy - eLogger v5.3 | ' + RouteName

  useEffect(  () => {
    // setIsLoading(true);
    // if (RouteModuleID) {
    //    authService
    //     .getModuleData(RouteModuleID)
    //     .then(async (data) => {
    //      let  moduleDetailsData =data.data; 
    //       if(moduleDetailsData){
    //         // console.log(moduleDetailsData['status'])
    //           if(moduleDetailsData['status']!=='ACTIVE'){
    //               setModuleActiveFlag(false)
    //           }
    //           if (moduleDetailsData['scope'] === 'PROTECTED') {
    //             await authService.checkUserAccessPermission(currentUser.token.accessToken, RouteModuleID).then((accessData) => {
    //             //  console.log("Access")
    //               SetAuthFlag(accessData)
    //               setIsLoading(false);
                  
    //             })
    //             }else{
    //               setIsLoading(false);
    //             }
    //       }
         
         
    //         setModuleDetails(data.data)
    //     })
    //     .catch((error) => {
    //       console.error('Failed to fetch module details:', error)
    //       setIsLoading(false);
    //     })
       
               

    // }


  }, [RouteModuleID])

  const { user: currentUser } = useSelector((state) => state.auth)
  let auth = false
  let accessAuth
  if (!RouteModuleID) {
    return outlet = <Navigate to="/404" />
  }
  // console.log("Protected Route")
  // console.log(moduleDetails)
  if(isModuleActive){
  if (moduleDetails && moduleDetails['scope'] === 'PROTECTED') {
    //  console.log("Protected Route Access")
    //  console.log(moduleDetails['scope'])
  if (!AuthFlag) {
    return outlet = <Navigate to="/401" />
  }
  }
  }else{
    return outlet = <Navigate to="/500" />
    
  }
  if (currentUser) {
    auth = authService.checkTokenExpiry(currentUser.token.accessToken) ? false : true
  }

  // return isLoading ? <div className='loading'>Loading...</div> : auth ? outlet : <Navigate to="/login" />
  return auth ? outlet : <Navigate to="/login" />

}
