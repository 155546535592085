import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import authService from './modules/core/services/auth.service'
import { history } from './modules/core/helper/history'
import { PrivateRoute } from './modules/core/middleware/PrivateRoute'
import { AuthRoute } from './modules/core/middleware/AuthRoute'


import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from './actions/types'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./modules/core/layout/DefaultLayout.js'))

// Pages
const Login = React.lazy(() => import('./modules/login/Login'))
const Page404 = React.lazy(() => import('./modules/errors/Page404'))
const Page401 = React.lazy(() => import('./modules/errors/Page401'))
const Page500 = React.lazy(() => import('./modules/errors/Page500'))

const App = () => {
  const dispatch = useDispatch()
  const { isLoggedIn, token } = useSelector((state) => state.auth)
  const { loading } = useSelector((state) => state.loading);

  useEffect(() => {
    if (loading) {
      return () => {
        dispatch({ type: 'FINISH_LOADING' })
      }
    }
  }, [loading])
  return (
    
    <HashRouter>
           

      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<AuthRoute />}>
            <Route path="/login" name="Login Page" element={<Login />} />
          </Route>

          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/401" name="Page 401" element={<Page401 />} />
          <Route path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="*" name="Home" element={<DefaultLayout />} errorElement={<Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </HashRouter>
  )
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  token: state.auth.user?.accessToken,
})
export default App
