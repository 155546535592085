import axiosInstance from '../axios/AxiosInterceptor'
import app_config from 'src/constants.js'
// const API_URL = 'https://core.cloudswitch.in/api/'
const API_URL = app_config.API_URL

class AuthService {
  register = (data) => {
    return axiosInstance.post(API_URL + 'auth/register', data)
  }

  login = (data) => {
    return axiosInstance.post(API_URL + 'auth/login', data)
  }

  resetPassword = (data) => {
    return axiosInstance.post(API_URL + 'auth/reset-password', data)
  }

  resetVerifyEmail = (token) => {
    return axiosInstance.post(API_URL + 'auth/reset-verify-email', { verificationkey: token })
  }

  resetNewPassword = (data) => {
    return axiosInstance.post(API_URL + 'auth/reset-new-password', data)
  }

  verifyEmail = (token) => {
    return axiosInstance.post(API_URL + 'auth/verify-email', { verificationkey: token })
  }

  checkEmailIdExist = (email) => {
    return axiosInstance.post(API_URL + 'auth/checkEmailIdExist', { email })
  }

  checkPhoneNumberExist = (phoneNumber) => {
    return axiosInstance.post(API_URL + 'auth/checkPhoneNumberExist', { phoneNumber })
  }

  checkTokenExpiry = (token) => {
    if (!token) {
      return true // No token, so consider it expired
    }
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const expiryTime = decodedToken.exp * 1000 // Convert to milliseconds
    const currentTime = Date.now()
    return currentTime > expiryTime
  }

  checkUserAccessPermission = async (token, moduleID) => {
    let accessAuth = false
    if (!token) {
      return false
    }
    try {
      const result = await axiosInstance.post(API_URL + 'auth/verify-access/' + moduleID, {
        verificationkey: token,
        module_id: moduleID,
      })
      // console.log(result.data.status)
      accessAuth = result.data.status
    } catch (e) {
      accessAuth = false
    }
    return accessAuth
    // return false
  }

  getModuleData = async (moduleID) => {
    return await axiosInstance.get(API_URL + 'app/module/get-moduledata/' + moduleID)
  }

  getUserMenu = async () => {
    return await axiosInstance.get(API_URL + 'app/menu/list')
  }
}

export default new AuthService()
