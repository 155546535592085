// Action types
export const SET_OUTLET_ID = 'SET_OUTLET_ID';
export const CLEAR_OUTLET_ID = 'CLEAR_OUTLET_ID';

// Action creators
export const setOutletId = (id) => ({
  type: SET_OUTLET_ID,
  payload: id
});

export const clearOutletId = () => ({
  type: CLEAR_OUTLET_ID
});
