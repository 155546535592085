import { SET_MESSAGE, CLEAR_MESSAGE } from '../actions/types'

const initialState = {
  message: '',
  flag: '',
}

export default function (state = initialState, action) {
  const { type, payload, flag } = action

  switch (type) {
    case SET_MESSAGE:
      return { message: payload, flag: flag }

    case CLEAR_MESSAGE:
      return { message: '', flag: '' }

    default:
      return state
  }
}
